html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  background-color: white !important;
}

body, h1, h2, h3, h4, h5, h6 {
  color: var(--secondary-color);
}

button:hover, a:hover {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

a {
  color: var(--secondary-color);
  text-decoration: underline;
}

.content {
  background-color: var(--white);
}

.container-fluid.main-content {
  padding: 0;
  padding-bottom: 20px;
}

.zerocopy-link, .zerocopy-link:link, .zerocopy-link:hover, .zerocopy-link:active, .zerocopy-link:visited {
  color: var(--secondary-color);
  font-weight: 600;
  text-decoration: none;
}

.zerocopy-link.active {
  background-color: var(--darkblue-color);
  color: var(--white);
  border-radius: 4px;
  padding: 4px;
}

.accent-link {
  color: var(--tangerine-color);
}

.accent-link:hover {
  color: var(--tangerine-dark-color);
}

.c-help {
  cursor: help;
}

.c-pointer {
  cursor: pointer;
}

.c-not-allowed {
  cursor: not-allowed;
}

.text-hover-bold:hover {
  font-weight: bold;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-underline, .text-hover-underline:hover {
  text-decoration: underline;
}

.ai-text {
  color: var(--ai-color);
}

.page-header {
  border-bottom: 3px solid var(--darkblue-lighter-color);
}

.text-capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

.very-small {
  font-size: 70%;
}

/* Negative margin */
.mr-4-neg {
  margin-right: -1rem !important;
}

/* Sizing */
.w-85 {
  width: 85%;
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none;
  }
}

@media (max-width: 768px) { /* Below MD */
  .d-sm-none {
    display: none;
  }
  .w-sm-100 {
    width: 100%;
  }
}

/* Notifications */

.notifications-tc {
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 66%;
  padding: 0px 10px 10px;
  z-index: 9998;
  box-sizing: border-box;
  height: auto;
}

.notification {
  border: none;
  border-radius: .25rem;
  position: relative;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  opacity: 1;
  transition: all 0.3s ease-in-out 0s;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform, opacity;
}

.notification-hidden {
  opacity: 0;
}

.notification-success {
  color: var(--secondary-color);
  border-color: transparent;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
}

.notification-error {
  color: var(--danger-color);
  background-color: var(--red-lighter-color);
}

.notification-warning {
  background-color: #fff3cd;
  color: #856404;
}

.notification-dismiss {
  visibility: hidden;
}

.notification-message {

}

.notification-dismiss:after {
  visibility: visible;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 10px;
  width: 18px;
  height: 18px;
  content:"";
  margin-top: 24px;
  background-size: 18px 18px;
  background-image: url("./images/close.svg");
}

.notification-message::before {
  display: inline-block;
  width: 24px;
  height: 24px;
  content:"";
  vertical-align: -27%;
  margin-right: 12px;
}

.notification-error .notification-message::before, .notification-warning .notification-message::before {
  background-size: 24px 24px;
  background-image: url("./images/warning.svg");
}

.notification-success .notification-message::before {
  background-size: 24px 24px;
  background-image: url("./images/confirmation.svg");
}

@media (max-width: 991.98px) {

  .notifications-tc {
    width: 95%;
    max-width: none;
  }

}

/* Custom styles to use react-select component with bootstrap control state */
.is-invalid .react-select__control {
  border-color: var(--red-color);
}

.react-select__menu {
  z-index: 9999 !important;
}

/* Override bootstrap styles */

.align-sub {
  vertical-align: sub !important;
}

/* Cards */

.card-header {
  background-color: var(--darkblue-lighter-color);
}

.card-footer {
  background-color: var(--darkblue-lighter-color);
}

.card-header-sm {
  padding: .3rem .8rem
}

.card-primary {
  border-color: var(--green-color);
}

.card-primary:not(.nohover):hover {
  border-color: var(--green-dark-color);
}

.card-primary .card-header {
  background-color: var(--green-color);
  border-color: var(--green-color);
  color: var(--white);
}

.card-primary:not(.nohover):hover .card-header, .card-primary.selected .card-header {
  background-color: var(--green-dark-color);
  border-color: var(--green-dark-color);
}

.card-danger {
  border-color: var(--red-color);
}

.card-danger:hover {
  border-color: var(--red-dark-color);
}

.card-danger .card-header {
  background-color: var(--red-color);
  border-color: var(--red-color);
}

.card-danger:hover .card-header {
  background-color: var(--red-dark-color);
  border-color: var(--red-dark-color);
}

.card-danger .card-body .border-primary {
  border-color: var(--red-color)!important;
}

.card-danger:hover .card-body .border-primary {
  border-color: var(--red-dark-color)!important;
}

.card-secondary {
  border-color: var(--darkblue-color);
}

.card-secondary:not(.nohover):hover {
  border-color: var(--darkblue-color);
}

.card-secondary > .card-header {
  background-color: var(--darkblue-color);
  border-color: var(--darkblue-color);
  color: var(--white);
}

.card-secondary:not(.nohover):hover .card-headere, .card-secondary.selected .card-header {
  background-color: var(--darkblue-color);
  border-color: var(--darkblue-color);
}

.card-secondary .card-body .border-secondary {
  border-color: var(--darkblue-color)!important;
}

.card-secondary:hover .card-body .border-secondary {
  border-color: var(--darkblue-color)!important;
}

/* Buttons */

.btn-xs {
  padding: .2rem .4rem;
  font-size: .700rem;
  line-height: 1.2;
  border-radius: .2rem;
}

.btn {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn:before {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-primary, .btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: var(--primary-dark-color);
  border-color: var(--primary-dark-color);
}

.btn-primary:disabled, .btn-primary.disabled {
  background-color: var(--primary-light-color);
  border-color: var(--primary-light-color);
}

.btn-outline-primary {
  color: var(--primary-dark-color);
  border-color: var(--primary-color);
}

.btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: var(--primary-dark-color);
}

.btn-outline-primary:disabled:hover, .btn-outline-primary.disabled:hover {
  color: var(--white);
}

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn-secondary:hover {
  background-color: var(--secondary-dark-color);
  border-color: var(--secondary-dark-color);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  background-color: var(--secondary-light-color);
  border-color: var(--secondary-light-color);
}

.btn-outline-secondary {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn-outline-secondary:hover, .btn-outline-secondary.active {
  color: var(--white);
  background-color: var(--secondary-color);
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: var(--white);
  background-color: var(--secondary-color);
}

.btn-outline-secondary-light {
  color: var(--secondary-color);
  background-color: var(--darkblue-lightest-color);
}

.btn-outline-secondary-light:hover, .btn-outline-secondary-light.active {
  color: var(--white);
  background-color: var(--darkblue-light-color);
}

.btn-outline-secondary-light:not(:disabled):not(.disabled).active, .btn-outline-secondary-light:not(:disabled):not(.disabled):active {
  color: var(--white);
  background-color: var(--secondary-color);
}

.btn-outline-secondary-light:disabled:hover, .btn-outline-secondary-light.disabled:hover {
  color: var(--secondary-color);
  background-color: var(--darkblue-lightest-color);
}

.btn-accent {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: var(--white);
}

.btn-accent:hover {
  background-color: var(--accent-dark-color);
  border-color: var(--accent-dark-color);
  color: var(--white);
}

.btn-accent:disabled, .btn-accent.disabled {
  background-color: var(--accent-light-color);
  border-color: var(--accent-light-color);
}

.btn-accent-2 {
  background-color: var(--tangerine-color);
  border-color: var(--tangerine-color);
  color: var(--white);
}

.btn-accent-2:hover {
  background-color: var(--tangerine-dark-color);
  border-color: var(--tangerine-dark-color);
  color: var(--white);
}

.btn-accent-2:disabled, .btn-accent-2.disabled {
  background-color: var(--tangerine-light-color);
  border-color: var(--tangerine-light-color);
  color: var(--white);
}

.btn-danger {
  background-color: var(--danger-color);
  border-color: var(--danger-color);
  color: var(--white);
}

.btn-danger:hover {
  background-color: var(--danger-dark-color);
  border-color: var(--danger-dark-color);
  color: var(--white);
}

.btn-danger:disabled, .btn-danger.disabled {
  background-color: var(--danger-light-color);
  border-color: var(--danger-light-color);
  color: var(--white);
}

.btn-ai {
  background-color: var(--ai-color);
  border-color: var(--ai-color);
  color: var(--white);
}

.btn-ai .ai-icon {
    /* --white  */
    filter: brightness(0) saturate(100%) invert(99%) sepia(86%) saturate(2%) hue-rotate(225deg) brightness(112%) contrast(101%);
}

.btn-ai:hover {
  background-color: var(--ai-dark-color);
  border-color: var(--ai-dark-color);
  color: var(--white);
}

.btn-ai:disabled, .btn-ai.disabled {
  background-color: var(--ai-light-color);
  border-color: var(--ai-light-color);
  color: var(--white);
}

.btn-outline-ai {
  color: var(--ai-color);
  border-color: var(--ai-color);
  background-color:  var(--white);
  .ai-icon {
    /* --ai-color */
    filter: brightness(0) saturate(100%) invert(23%) sepia(98%) saturate(4132%) hue-rotate(295deg) brightness(100%) contrast(132%);
  }
}

.btn-outline-ai:hover {
  color: var(--white);
  border-color: var(--ai-light-color);
  background-color:  var(--ai-dark-color);
  .ai-icon {
    /* --white */
    filter: brightness(0) saturate(100%) invert(99%) sepia(86%) saturate(2%) hue-rotate(225deg) brightness(112%) contrast(101%);
  }
}

.btn-outline-ai:disabled, .btn-outline-ai.disabled {
  color: var(--ai-light-color);
  border-color: var(--ai-light-color);
  .ai-icon {
    /* --ai-light-color */
    filter: brightness(0) saturate(100%) invert(54%) sepia(57%) saturate(1406%) hue-rotate(281deg) brightness(106%) contrast(101%);
  }
}

.btn-secondary-ai {
  border-radius: 10px;
  background-color: var(--ai-secondary-color);
  border-color: var(--ai-secondary-color);
  color: var(--white);
  .ai-icon {
    /* --white */
    filter: brightness(0) saturate(100%) invert(99%) sepia(86%) saturate(2%) hue-rotate(225deg) brightness(112%) contrast(101%);
  }
}

.btn-secondary-ai:hover {
  background-color: var(--ai-color);
  border-color: var(--ai-color);
  color: var(--white);
  .ai-icon {
    /* --white */
    filter: brightness(0) saturate(100%) invert(99%) sepia(86%) saturate(2%) hue-rotate(225deg) brightness(112%) contrast(101%);
  }
}

.btn-secondary-ai:disabled, .btn-secondary-ai.disabled {
  background-color: grey;
  border-color: grey;
  color: var(--white);
  .ai-icon {
    /* --white */
    filter: brightness(0) saturate(100%) invert(99%) sepia(86%) saturate(2%) hue-rotate(225deg) brightness(112%) contrast(101%);
  }
}

.svg-grey {
  filter: brightness(0) saturate(100%) invert(50%) sepia(6%) saturate(247%) hue-rotate(212deg) brightness(91%) contrast(90%);
}

.svg-secondary-color {
  filter: brightness(0) saturate(100%) invert(23%) sepia(10%) saturate(1616%) hue-rotate(169deg) brightness(97%) contrast(94%);
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.btn-link {
  color: var(--secondary-color);
  text-decoration: underline;
}
.btn-link:hover {
  color: var(--secondary-color);
  font-weight: bold;
}

/* Borders */

.border-bottom {
  border-bottom: 1px solid var(--darkblue-lighter-color)!important;
}

.border-top {
  border-top: 1px solid var(--darkblue-lighter-color)!important;
}

.border-primary {
  border-color: var(--primary-color);
}

.border-primary-light {
  border-color: var(--primary-light-color);
}

/* Table */

.table {
  color: var(--secondary-color)
}
.table td, .table th {
  vertical-align: middle;
}

.alert-sm {
  padding: 0.5rem 0.75rem;
}

.alert-success {
  background-color: var(--primary-light-color);
  border-color: var(--primary-light-color);
  color: var(--primary-color);
}

.alert-danger {
  color: var(--danger-color);
  border-color: var(--danger-light-color);
  background-color: var(--danger-light-color);
  border-left-width: 5px;
  border-left-color: var(--danger-color);
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--darkblue-color) !important;
}

.text-secondary-dark {
  color: var(--secondary-color) !important;
}

.text-danger {
  color: var(--danger-color) !important;
}

.text-success {
  color: var(--primary-color) !important;
}

.form-control.is-invalid, .form-control:invalid {
  border-color: var(--danger-color);
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
  border-right-width: 1px !important;
}

.form-control-xs {
  height: calc(1em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: .75rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.facebook-button {
  background-color: var(--facebook-blue);
  color: var(--white);
  border-radius: 4px;
  padding: .5rem;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.facebook-button i {
  padding-right: .5rem;
}

.page-in {
  animation-name: fadein;
  animation-duration: 1s;
}

input[type='checkbox'] {
  cursor: pointer;
}
input[type='checkbox']:disabled {
  cursor: not-allowed;
}

input[type='checkbox'].big-1 {
  transform: scale(1.2);
}

input[type='checkbox'].big-2 {
  transform: scale(1.5);
}

input[type='checkbox'].big-3 {
  transform: scale(2);
}

/* Necessary to fix problem with MUI toolbar */
div:has(div > div.modal) {
  z-index: 2500 !important;
}

.modal-menu {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  width: 97%;
  max-width: 100%;
}

.modal-menu .modal-content {
  margin-bottom: 5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.modal-menu .modal-content .menu-item {
  padding: 1rem;
  padding-left: 2rem;
}

.modal-menu .modal-content .menu-item .icon {
  min-width: 40px;
}

.modal-menu .modal-content .menu-item .text {
 color: var(--secondary-color) !important;
}

/* accordion */

.accordion .card {
  border-radius: 0;
}

.accordion>.card:is(:last-of-type).card-primary {
  border-bottom: 1px solid var(--green-color);
}

.accordion .card .card-header {
  border-radius: 0;
}

/* Font size classes from Bootstrap 5.2 */

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

/* Background from Bootstrap */

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-primary-light {
  background-color: var(--primary-light-color) !important;
}

.bg-primary.disabled {
  background-color: var(--primary-light-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}

.bg-text-color {
  background-color: var(--darkblue-color)
}

.bg-accent-light {
  background-color: var(--accent-light-color);
}

/* Pagination from Bootstrap */
.pagination .page-item .page-link {
  color: var(--secondary-color)
}

.pagination .page-item .page-link:hover {
  background-color: var(--darkblue-lighter-color);
}

.pagination .page-item.active .page-link {
  color: var(--white);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

/* Cards */
.card.zc-card {
  background-color: var(--primary-light-color);
  border-color: var(--primary-light-color);
}

.zc-card .card-body {
  padding: .5rem;
}

.zc-card .card-footer {
  background-color: var(--primary-light-color);
  border-color: var(--primary-light-color);
}

.card.zc-card .card-body p {
  margin-bottom: 0;
  line-height: 1.25;
}

#inviteFriendsInfoModal {
  max-width: 700px;
}

@media (min-width: 576px) and (max-width: 800px) {

  #inviteFriendsInfoModal {
      max-width: 95%;
  }
}

.left-menu-container {
  border: 1px solid var(--darkblue-light-color);
  border-radius: 4px;
}

.left-menu {
  font-size: 1.2rem;
}

.left-menu .title {
  font-size: 1.5rem;
}

.left-menu a {
  color: var(--secondary-color);
  text-decoration: none;
}

.left-menu a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

.left-menu a i {
  font-size: 1.5rem;
}

.main-menu a {
  text-decoration: none;
}

.main-menu a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

/* Placeholder for images */
.bd-placeholder-img {
  font-size: 1.125rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-anchor: middle;
}

/* Freshdesk widget */

@media (max-width: 768px) { /* Below MD */
  #freshworks-container #launcher-frame {
    bottom: 50px !important;
  }
}
@media (min-width: 768px) { /* MD */
  #freshworks-container #launcher-frame {
    bottom: 90px !important;
  }
}


/* No Border */
.no-border {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.filter-muted {
  filter: invert(47%) sepia(17%) saturate(240%) hue-rotate(167deg) brightness(92%) contrast(88%);
}

.cursor-pointer {
  cursor: pointer;
}

/* AdSense ads */
.small-adsense {
  width: 250px;
  height: 250px;
}

