@media (max-width: 767) {
  .quiz-start {
    max-width: 70%;
  }
}

@media (min-width: 768px) {
  .quiz-start {
    max-width: 45%;
  }
}

@media (min-width: 992px) {
  .quiz-start {
    max-width: 40%;
  }
}

@media (min-width: 1200px) {
  .quiz-start {
    max-width: 35%;
  }
}

@media (min-width: 1500px) {
  .quiz-start {
    max-width: 30%;
  }
}

@media (min-width: 1800px) {
  .quiz-start {
    max-width: 25%;
  }
}
