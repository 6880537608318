

.slide-in {
  animation: slideInLeft 0.5s backwards ease-in-out;
}

.slide-out {
  animation: slideOutLeft 0.5s forwards ease-in-out;
}


.content-slide-right {
  animation: slideRight 0.3s backwards ease-in-out;
}

.content-slide-back {
  animation: slideBack 0.3s none ease-in-out;
}

