.item {
  transition: transform 300ms ease 100ms;
  margin: 0;
  background-color: white;
  position: relative;
  border: 1px solid var(--darkblue-color);
  border-radius: 0px;
  display: flex;
  justify-content: center;
  height: 2rem;
  align-items: center;
}

.item--answered {
  color: white;
  background-color: var(--darkblue-color);
}

.item--active {
  color: white;
  background-color: var(--primary-dark-color) !important;
  border: 1px solid var(--darkblue-color) !important;
  transform: scale(1.2) !important;
  transform: translateX(-25%);
  z-index: 10;
}

.item--correct {
  color: var(--green-dark-color);
  background-color: var(--green-light-color) !important;
  border: 1px solid var(--green-dark-color) !important;
}

.item--correct:hover {
  color: white;
  background-color: var(--green-dark-color) !important;
}

.item--incorrect {
  color: var(--tangerine-dark-color);
  background-color: var(--tangerine-light-color) !important;
  border: 1px solid var(--tangerine-dark-color) !important;
}

.item--incorrect:hover {
  color: white;
  background-color: var(--tangerine-dark-color) !important;
}

.item.first {
  border-radius: 20px 0 0 20px;
}

.item.last {
  border-radius: 0 20px 20px 0 ;
}

