.ai-steps-container {

}

.ai-steps-container .ai-step-card {
  width: 98%;
  transition: all .3s ease-in-out;
}

.ai-steps-container .ai-step-card:hover {
  transform: scale(1.03);
  transition: all .3s ease-in-out;
}

@media (min-width: 576px) {

  .ai-steps-container .ai-step-card {
    width: 80%;
  }

}

@media (min-width: 768px){

  .ai-steps-container .ai-step-card {
    width: 30%;
  }

}

@media (min-width: 992px) {

  .ai-steps-container .ai-step-card {
    width: 25%;
  }

}