.option {
    background: var(--darkblue-lighter-color);
}

.quiz:hover {
    cursor: pointer;
    outline: 0.5px solid var(--darkblue-color);
}

.option.summary.correct {
    outline: 0.5px solid var(--green-color);
}

.answered {
    color: white;
    background-color: var(--darkblue-color);
    outline: 0.5px solid var(--darkblue-color);
}


.option.summary.incorrect {
    outline: 0.5px solid var(--tangerine-dark-color);
}

.label {
    /* margin-bottom: -0.35rem; */
    margin-left: auto;
    margin-top: 0;
}

.label.correct {
    color: var(--green-color) !important;
    outline: 0.5px solid var(--green-color);
}

.label.incorrect {
    color: var(--tangerine-dark-color) !important;
    outline: 0.5px solid var(--tangerine-dark-color);
}



