

.FlashcardArrayWrapper {
  box-sizing: border-box;
  gap: 10px;
  display: flex; 
  width: 100%; 
  height: 100%;
  height: auto;
}

.FlashcardArrayWrapper * {
  box-sizing: border-box;
}

.FlashcardArrayWrapper__CardHolder {
  perspective: 1000px;
  width: 100%; 
  display: flex; 
  flex-direction: row; 
}

.FlashcardArrayWrapper__CardHolder .FlashcardWrapper:nth-child(1),
.FlashcardArrayWrapper__CardHolder .FlashcardWrapper:nth-child(3) {
  opacity: 0;
  box-shadow: none;
  pointer-events: none;
  visibility: hidden;
  background: transparent;
  display: block !important;
  transform-style: preserve-3d;
  transition: transform 0.24s ease, opacity 0.12s linear !important;
}

.FlashcardArrayWrapper__CardHolder .FlashcardWrapper:nth-child(1) {
  z-index: 5;
  right: unset;
  width: 0px !important;
  transform: translateX(-16%) rotateY(16deg) translateZ(0) !important;
}

.FlashcardArrayWrapper__CardHolder .FlashcardWrapper:nth-child(1) .FlashcardWrapper__item--content {
  display: none;
}

.FlashcardArrayWrapper__CardHolder .FlashcardWrapper:nth-child(3) {
  z-index: 5;
  width: 0px !important;
  left: unset;
  transform: translateX(16%) rotateY(-16deg) translateZ(0) !important;
}

.FlashcardArrayWrapper__CardHolder .FlashcardWrapper:nth-child(3) .FlashcardWrapper__item--content {
  display: none;
}

.FlashcardArrayWrapper__CardHolder .FlashcardWrapper:nth-child(2) {
  z-index: 6;
  box-shadow: none;
  background: transparent;
  display: block !important;
  transform-style: preserve-3d;
  transition: transform 0.4s ease, opacity 0.12s linear !important;
}

.FlashcardArrayWrapper__controls {
  margin-top: 1rem !important;
  gap: 35px;
  display: flex; 
  justify-content: center;
  width: 100%; 
  height: 40px; 
  flex-direction: row; 
}

.FlashcardArrayWrapper__controls button {
  padding: 0;
  border: none;
  background-color: transparent;
}

.MuiLinearProgress-colorPrimary {
  background-color: var(--secondary-light-color) !important;
}

.MuiLinearProgress-barColorPrimary{
  background-color: var(--secondary-color) !important;
}

