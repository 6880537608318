.slider {
  display: flex;
  position: relative;
  margin-top: 1rem;
}

.slider__container {
  display: flex;
  transition: transform 300ms ease 100ms;
  z-index: 3;
  width: 100%;
}

.slider:hover .item {
  cursor: pointer;
}

