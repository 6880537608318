
@media (max-width: 767) {
  .ai-container {
    max-width: 98%;
  }
}

@media (min-width: 768px) {
  .ai-container {
    max-width: 98%;
  }
}

@media (min-width: 992px) {
  .ai-container {
    max-width: 98%;
  }
}

@media (min-width: 1200px) {
  .ai-container {
    max-width: 92%;
  }
}

@media (min-width: 1500px) {
  .ai-container {
    max-width: 85%;
  }
}

@media (min-width: 1800px) {
  .ai-container {
    max-width: 75%;
  }
}
