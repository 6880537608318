.small-cell {
  max-width: 70px;
  min-width: 30px;
}

.date-cell {
  max-width: 90px;
  min-width: 80px;
}

.name-cell {
  width: 30%;
}

.table-row:hover {
  background-color: var(darkblue-color);
  cursor: pointer;
}
