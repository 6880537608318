.slide-button {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  border: 0;
  outline: 0;
  padding: 0;
  z-index: 4;
  height: 100%;
}

.slide-button span {
  width: 25px;
  color: #fff;
  display: block;
  margin: 0 auto;
}

.slide-button--next {
  right: 0;
}


.slide-button--prev {
  left: 0;
}

.slide-button--prev span {
  transform: rotateZ(180deg);
}
