.score {
    border-radius: 15px;
    border-style: solid;
    border-width: 2px;
    width: 3rem;
    text-align: center;
}

.score.correct {
    border-color: var(--green-color);
}

.score.incorrect {
    border-color: var(--tangerine-dark-color);
}

.quiz-container {
    border-radius: 25px;
    background: var(--darkblue-lightest-color);
    padding: 1rem;
}

#scrollToTopBtn {
    position: fixed;
    bottom: 50px;
    right: 20px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


body.onscroll {
    display: block; /* Show button when page is scrolled */
}


@media (max-width: 768) {
  .summary-container {
    max-width: 98%;
  }
}

@media (min-width: 768px) {
  .summary-container {
    max-width: 95%;
  }
}

@media (min-width: 992px) {
  .summary-container {
    max-width: 85%;
  }
}

@media (min-width: 1200px) {
  .summary-container {
    max-width: 90%;
  }
}

@media (min-width: 1500px) {
  .summary-container {
    max-width: 75%;
  }
}

@media (min-width: 1800px) {
  .summary-container {
    max-width: 55%;
  }
}
