.FlashcardWrapper {
  box-sizing: border-box;
  bottom: 10%;
  clear: left;
  perspective: 1000px;
  width: 99%; 
  height: 100%;
  transform: none !important;
}

.FlashcardWrapper * {
  box-sizing: border-box;
}

.FlashcardWrapper__item {
  padding: 1rem;
  top: 0;
  left: 0;
  position: absolute;
  transform-style: preserve-3d;
  background-color: transparent;
  transition: transform 0.45s ease;
  width: 100%; 
  height: 100%; 
}

.FlashcardWrapper__item--front,
.FlashcardWrapper__item--back {
  top: 0;
  left: 0;
  color: black;
  position: absolute;
  border-radius: inherit;
  display: flex; 
  justify-content: center;
  align-items: center;
  align-content: center;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: 1px solid var(--secondary-color);

}

.FlashcardWrapper__item--front:not(.hide),
.FlashcardWrapper__item--back:not(.hide) {
  box-shadow: 6px 6px 0 0 rgba(0, 0, 0, 0.3),
              12px 12px 0 0 rgba(0, 0, 0, 0.2),
              18px 18px 0 0 rgba(0, 0, 0, 0.1); 
}

.FlashcardWrapper__item--front {
  background-color: #ffffff;
}

.FlashcardWrapper__item--back {
  background-color: #ffffff;
  transform: rotateX(-180deg);
}

.FlashcardWrapper__item--flip {
  transform: rotateX(180deg) !important;
}

.FlashcardWrapper__item--content {
  border-radius: inherit;
}

.flip-text {
    background-color: var(--darkblue-color);
    margin-top: auto;
    width: 100%;
    color: white;
    border-radius: 0 0 0.85rem 0.9rem;
    border: 1px solid var(--darkblue-color);
    text-align: center;
    font-style: normal;
    padding-top: 0.25rem;
    
}
